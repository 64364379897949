* {
	margin: 0;
	padding: 0;
}

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layer-checkbox {
  visibility: hidden;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}